import { createContext, useEffect, useMemo, useState } from "react";

import useAuth from "hooks/useAuth";

const STORAGE_KEY = "currentOrganisationId";

interface CurrentOrganisationIdProviderProps {
  children: React.ReactNode;
}
interface CurrentOrganisationIdContextProps {
  currentOrganisationId: string;
  setCurrentOrganisationId: (org: string) => void;
}

export const CurrentOrganisationIdContext = createContext<
  CurrentOrganisationIdContextProps | undefined
>(undefined);

export const CurrentOrganisationIdProvider = ({
  children,
}: CurrentOrganisationIdProviderProps) => {
  const { organisationId } = useAuth();

  const [currentOrganisationId, setCurrentOrganisationId] =
    useState(organisationId);

  useEffect(() => {
    const storedOrganisationId = window.localStorage.getItem(STORAGE_KEY);
    if (storedOrganisationId) {
      setCurrentOrganisationId(storedOrganisationId);
    }
  }, []);

  useEffect(() => {
    if (organisationId && !currentOrganisationId) {
      setCurrentOrganisationId(organisationId);
    }
  }, [organisationId]);

  useEffect(() => {
    if (currentOrganisationId) {
      window.localStorage.setItem(
        STORAGE_KEY,
        currentOrganisationId || organisationId.toString()
      );
    } else {
      window.localStorage.removeItem(STORAGE_KEY);
    }
  }, [currentOrganisationId]);

  const value = useMemo(
    () => ({ currentOrganisationId, setCurrentOrganisationId }),
    [currentOrganisationId]
  );

  return (
    <CurrentOrganisationIdContext.Provider value={value}>
      {children}
    </CurrentOrganisationIdContext.Provider>
  );
};
